// Configuration object for calendar localization
const CalendarConfig = {
  sl: {
    months: [
      "Januar",
      "Februar",
      "Marec",
      "April",
      "Maj",
      "Junij",
      "Julij",
      "Avgust",
      "September",
      "Oktober",
      "November",
      "December",
    ],
    weekdays: ["Ned", "Pon", "Tor", "Sre", "Čet", "Pet", "Sob"],
    navigation: {
      previous: "Prejšnji",
      next: "Naslednji",
    },
    upcomingEvents: "Kmalu",
    noUpcomingEvents: "Ni napovedanih dogodkov",
  },
  en: {
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    navigation: {
      previous: "Prev",
      next: "Next",
    },
    upcomingEvents: "Upcoming Events",
  },
};

// Calendar Component
class Calendar {
  constructor(container, config = {}) {
    this.container = container;
    this.events = [];
    this.currentDate = new Date();
    this.displayedMonth = this.currentDate.getMonth();
    this.displayedYear = this.currentDate.getFullYear();
    this.isAnimating = false;
    this.isLoading = false;
    this.hasError = false;

    this.config = {
      language: config.language || "en",
      firstDayOfWeek: config.firstDayOfWeek || 1,
      customLocale: config.customLocale || null,
      apiEndpoint: config.apiEndpoint || "/api/?module=events",
    };

    this.locale =
      this.config.customLocale ||
      CalendarConfig[this.config.language] ||
      CalendarConfig.en;

    // Render calendar immediately and fetch events after
    this.render();
    this.fetchEvents();
  }

  async fetchEvents() {
    try {
      this.isLoading = true;
      this.hasError = false;
      this.updateStatus();

      const startDate = new Date(this.displayedYear, this.displayedMonth, 1);
      const endDate = new Date(this.displayedYear, this.displayedMonth + 1, 0);

      const params = new URLSearchParams({
        start: startDate.toISOString().split("T")[0],
        end: endDate.toISOString().split("T")[0],
      });

      const response = await fetch(`${this.config.apiEndpoint}&${params}`);

      if (!response.ok) {
        throw new Error("Failed to fetch events");
      }

      const res = await response.json();
      this.events = res.payload;
      this.hasError = false;
    } catch (error) {
      console.error("Error fetching events:", error);
      this.events = [];
      this.hasError = true;
    } finally {
      this.isLoading = false;
      this.render();
    }
  }

  updateStatus() {
    const statusContainer = this.container.querySelector(".calendar-status");
    if (!statusContainer) return;

    if (this.isLoading) {
      statusContainer.innerHTML = `
        <div class="flex items-center text-gray-500 text-sm">
          <div class="animate-spin h-4 w-4 border-b-2 border-gray-500 rounded-full mr-2"></div>
          Nalagam dogodke...
        </div>`;
    } else if (this.hasError) {
      statusContainer.innerHTML = `
        <div class="text-red-500 text-sm">
          Prišlo je do težav pri nalaganju dogodkov.
        </div>`;
    } else {
      statusContainer.innerHTML = "";
    }
  }

  render() {
    const calendarHTML = `
    <div class="container absolute left-0 right-0 top-0 sm:top-12 md:top-36 lg:top-56 mx-auto rounded-2xl bg-white p-6 px-4">
  <div class="grid grid-cols-1 justify-items-center gap-8 md:grid-cols-2">
    <div class="bg-gray h-full w-full rounded-2xl p-6">
      <h3 class="heading text-2xl">${this.locale.upcomingEvents}</h3>
      <div class="flex flex-col space-y-2">
      ${this.getUpcomingEvents()}
      </div>
    </div>
    <div class="min-w-96">
    <div class="calendar font-poppins bg-white">
      <div class="flex justify-between items-center mb-4 bg-gray rounded-full">
        <button id="prevMonth" aria-label="Prejšnji mesec" class="text-gray-600 hover:text-gray-800 p-2 group hover:bg-white rounded-full border border-transparent hover:border-gray">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m15 18-6-6 6-6" /></svg>
        </button>
        <h2 class="text-xl font-semibold">
          ${this.getMonthName(this.displayedMonth)} ${this.displayedYear}
        </h2>
        <button id="nextMonth" aria-label="Naslednji mesec" class="text-gray-600 hover:text-gray-800 p-2 group hover:bg-white rounded-full border border-transparent hover:border-gray">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
        </button>
      </div>

      <div class="calendar-wrapper overflow-hidden relative">
      <!-- Status message container -->
      <div class="calendar-status flex items-center justify-center absolute left-0 right-0 top-0 bottom-0"></div>

        <div class="calendar-slider flex transition-transform duration-300 ease-in-out">
          <div class="calendar-month w-full flex-shrink-0">
            ${this.getCalendarContent(this.displayedYear, this.displayedMonth)}
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>`;

    this.container.innerHTML = calendarHTML;
    this.addEventListeners();
    this.updateStatus();
  }

  getCalendarContent(year, month) {
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const daysInMonth = lastDay.getDate();

    let startingDay = firstDay.getDay();
    if (this.config.firstDayOfWeek === 1) {
      startingDay = startingDay === 0 ? 6 : startingDay - 1;
    }

    let html = '<div class="grid grid-cols-7 gap-1">';

    // Add weekday headers
    this.getWeekdays().forEach((day) => {
      html += `<div class="text-center text-gray-600 text-sm py-2">${day}</div>`;
    });

    // Add empty cells for days before the first of the month
    for (let i = 0; i < startingDay; i++) {
      html += '<div class="p-2"></div>';
    }

    // Add days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const today = new Date();
      const isToday = date.toDateString() === today.toDateString();

      // Get events for this day
      const dayEvents = this.events.filter((event) => {
        const eventDate = new Date(event.date);
        return eventDate.toDateString() === date.toDateString();
      });

      const hasEvents = dayEvents.length > 0;
      const dateStr = date.toISOString().split("T")[0]; // YYYY-MM-DD format

      html += `
        <div class="p-2 text-center relative ${isToday ? "bg-black text-accent font-bold rounded-xl" : ""}
          ${hasEvents ? "font-bold text-blue-600 cursor-pointer hover:bg-gray-100" : ""}"
          ${hasEvents ? `data-date="${dateStr}" data-events='${JSON.stringify(dayEvents)}'` : ""}>
          ${day}
          ${hasEvents ? '<div class="w-1 h-1 bg-blue-600 rounded-full absolute bottom-1 left-1/2 transform -translate-x-1/2"></div>' : ""}
        </div>`;
    }

    html += "</div>";
    return html;
  }

  getMonthName(month) {
    return this.locale.months[month];
  }

  getWeekdays() {
    let weekdays = [...this.locale.weekdays];
    // Rotate array if week starts on Monday
    if (this.config.firstDayOfWeek === 1) {
      weekdays = [...weekdays.slice(1), weekdays[0]];
    }
    return weekdays;
  }

  async changeMonth(delta) {
    if (this.isAnimating) return;
    this.isAnimating = true;

    let newMonth = this.displayedMonth + delta;
    let newYear = this.displayedYear;

    if (newMonth > 11) {
      newMonth = 0;
      newYear++;
    } else if (newMonth < 0) {
      newMonth = 11;
      newYear--;
    }

    const slider = this.container.querySelector(".calendar-slider");
    const currentMonth = slider.querySelector(".calendar-month");

    // Create new month element
    const newMonthElement = document.createElement("div");
    newMonthElement.className = "calendar-month w-full flex-shrink-0";
    newMonthElement.innerHTML = this.getCalendarContent(newYear, newMonth);

    // Reset transform
    slider.style.transform = "";

    // Position new month based on direction
    if (delta > 0) {
      // Next: new month starts at right
      newMonthElement.style.transform = "translateX(100%)";
      slider.appendChild(newMonthElement);
    } else {
      // Prev: new month starts at left
      newMonthElement.style.transform = "translateX(-100%)";
      slider.appendChild(newMonthElement);
    }

    // Force browser reflow
    newMonthElement.offsetHeight;

    // Animate both months
    requestAnimationFrame(() => {
      slider.style.transition = "transform 300ms ease-in-out";
      newMonthElement.style.transition = "transform 300ms ease-in-out";

      if (delta > 0) {
        currentMonth.style.transform = "translateX(-100%)";
        newMonthElement.style.transform = "translateX(0)";
      } else {
        currentMonth.style.transform = "translateX(100%)";
        newMonthElement.style.transform = "translateX(0)";
      }
    });

    // Update month and year
    this.displayedMonth = newMonth;
    this.displayedYear = newYear;

    // Update header text immediately
    this.container.querySelector("h2").textContent =
      `${this.getMonthName(this.displayedMonth)} ${this.displayedYear}`;

    // Cleanup after animation
    setTimeout(() => {
      currentMonth.remove();
      slider.style.transition = "";
      newMonthElement.style.transition = "";
      this.isAnimating = false;

      // Re-attach event listeners
      this.addEventListeners();
    }, 300);

    // Fetch new events without blocking the animation
    this.fetchEvents();
  }

  addEventListeners() {
    // Remove existing listeners first
    const prevBtn = this.container.querySelector("#prevMonth");
    const nextBtn = this.container.querySelector("#nextMonth");

    // Clone and replace buttons to remove old event listeners
    if (prevBtn) {
      const newPrevBtn = prevBtn.cloneNode(true);
      prevBtn.parentNode.replaceChild(newPrevBtn, prevBtn);
      newPrevBtn.addEventListener("click", () => this.changeMonth(-1));
    }

    if (nextBtn) {
      const newNextBtn = nextBtn.cloneNode(true);
      nextBtn.parentNode.replaceChild(newNextBtn, nextBtn);
      newNextBtn.addEventListener("click", () => this.changeMonth(1));
    }

    // Add event listeners for day cells with events
    const daysWithEvents = this.container.querySelectorAll("[data-events]");
    daysWithEvents.forEach((day) => {
      day.addEventListener("click", (e) => {
        const events = JSON.parse(e.currentTarget.dataset.events);
        this.showEventPopup(events, e.currentTarget);
      });
    });
  }

  showEventPopup(events, element) {
    // Remove any existing popup
    const existingPopup = document.querySelector(".event-popup");
    existingPopup?.remove();

    // Create popup content
    const popup = document.createElement("div");
    popup.className =
      "event-popup absolute bg-white p-4 rounded-lg shadow-lg z-50 w-64";

    const eventsList = events
      .map(
        (event) => `
          <div class="mb-2 last:mb-0">
            <div class="font-bold">${event.title}</div>
            <div class="text-sm text-gray-600">${event.time || ""}</div>
            <div class="text-sm text-gray-600">${event.location || ""}</div>
            ${event.description ? `<div class="text-sm mt-1">${event.description}</div>` : ""}
          </div>`,
      )
      .join("");

    popup.innerHTML = eventsList;

    // Position popup
    const rect = element.getBoundingClientRect();
    const calendarRect = this.container.getBoundingClientRect();

    popup.style.top = `${rect.bottom - calendarRect.top + 5}px`;
    popup.style.left = `${rect.left - calendarRect.left}px`;

    // Add click outside listener to close popup
    const closePopup = (e) => {
      if (!popup.contains(e.target) && e.target !== element) {
        popup.remove();
        document.removeEventListener("click", closePopup);
      }
    };

    // Delay adding the click listener to prevent immediate closure
    setTimeout(() => {
      document.addEventListener("click", closePopup);
    }, 0);

    this.container.appendChild(popup);
  }

  getUpcomingEvents() {
    if (this.isLoading) {
      return '<div class="text-gray-500">Loading events...</div>';
    }

    if (this.hasError) {
      return '<div class="text-gray-500">Unable to load events</div>';
    }

    const today = new Date();
    const futureEvents = this.events
      .filter((event) => new Date(event.date) >= today)
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .slice(0, 3);

    if (futureEvents.length === 0) {
      return `<div class="text-gray-500">${this.locale.noUpcomingEvents || "No upcoming events"}</div>`;
    }

    return futureEvents
      .map((event) => {
        const date = new Date(event.date);
        return `
        <a
          href="${event.link}"
          class="group flex items-start gap-4 rounded-2xl p-2 hover:bg-white">
          <div class="flex-shrink-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="size-6 text-cyan-500"
            >
              <path
                d="M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5"
              />
              <path d="M16 2v4" />
              <path d="M8 2v4" />
              <path d="M3 10h5" />
              <path d="M17.5 17.5 16 16.3V14" />
              <circle cx="16" cy="16" r="6" />
            </svg>
          </div>
          <div class="flex-1">
            <h3 class="font-roboto mb-1 font-medium text-gray-700">
              ${event.title}
            </h3>
            <div class="mb-2 flex items-center gap-2">
              <span class="font-poppins text-sm text-gray-600">${date.getDate()}. ${this.getMonthName(date.getMonth())}, ${date.getFullYear()} ${event.time ? ` - ${event.time}` : ""}</span
              >
            </div>
            <p class="font-roboto text-sm text-gray-500">
              ${event.description}
            </p>
          </div>
        </a>`;
      })
      .join("");
  }
}

// Usage
// const events = [
//   { date: "2024-10-05T10:00:00", title: "Morning Meeting" },
//   { date: "2024-10-05T14:00:00", title: "Afternoon Workshop" },
//   { date: "2024-10-10T12:00:00", title: "Birthday Lunch" },
//   { date: "2024-10-15T09:00:00", title: "Conference Day 1" },
//   { date: "2024-10-15T14:00:00", title: "Conference Day 1 - Keynote" },
//   { date: "2024-10-16T09:00:00", title: "Conference Day 2" },
// ];

const calendarContainer = document.getElementById("calendar");
const calendar = new Calendar(calendarContainer, { language: "sl" });
